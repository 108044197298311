import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, Row } from "react-bootstrap";
import { Modal } from "flowbite-react";
import routes from "../routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import TranslateService from "services/translateService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Translates = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const [searchQuery, setSearchQuery] = useState('');
  const [translates, setTranslates] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [createModalShower, setOpenCreateModal] = useState(false);
  const [formData, setFormData] = useState({
    key: "",
    en: "",
    az: "",
    id: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const loadTranslates = async () => {
    const data = await TranslateService.getTranslates();
    setTranslates(data?.result);
  };
  useEffect(() => {
    loadTranslates();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const getData = (item) => {
    setOpenModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setCurrentData({});
  };

  const location = useLocation();
  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    if (isValid) {
      try {
        const response = await TranslateService.create(formData);
        if (response?.statusCode === 200) {
          loadTranslates();
          toast.success(response.message);
          toggleCreateModal();
          reset()
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  // edit
  async function edit(request) {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    if (isValid) {
      try {
        const response = await TranslateService.edit(formData);
        if (response?.statusCode === 200) {
          loadTranslates();
          toast.success(response.message);
          onCloseModal();
          setFormData([]);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }

  const toggleCreateModal = () => {
    setOpenCreateModal(false);
  };

  const deleteData = async (id) => {
    const response = await TranslateService.delete(id);
    if (response?.statusCode === 200) {
      loadTranslates();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };


  const filteredData = translates.filter(item =>
    item.az.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };


  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => setOpenCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          className="ml-3"
          onChange={handleSearchInputChange}
        />
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">key</th>
              <th className="border-0">az</th>
              <th className="border-0">en</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              ?.sort((a, b) => a.az.localeCompare(b.az))
              .map((item, index) => (
                <tr key={index}>
                  <td className="text-right">{item.key}</td>
                  <td>{item.az}</td>
                  <td>{item.en}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>

      {/* edit */}
      <Modal
        className="myModal"
        show={openModal}
        size="sm"
        onClose={() => onCloseModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  key{" "}
                  {errors.key && (
                    <span className="text text-danger">
                      {errors.key.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="key"
                  onChange={handleChange}
                  className={`form-control ${errors.key && "is-invalid"}`}
                  {...register("key", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  az{" "}
                  {errors.az && (
                    <span className="text text-danger">
                      {errors.az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="az"
                  onChange={handleChange}
                  className={`form-control ${errors.az && "is-invalid"}`}
                  {...register("az", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>

              <div className="form-group">
                <label>
                  en{" "}
                  {errors.en && (
                    <span className="text text-danger">
                      {errors.en.message}
                    </span>
                  )}{" "}
                </label>
                <input
                  type="text"
                  name="en"
                  onChange={handleChange}
                  className={`form-control ${errors.en && "is-invalid"}`}
                  {...register("en", {
                    required: "Boş ola bilməz.",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(edit)}>
            Dəyiş
          </Button>
          <Button className="btn-danger" onClick={() => onCloseModal(false)}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create */}
      <Modal
        className="myModal"
        show={createModalShower}
        size="sm"
        onClose={() => toggleCreateModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  key{" "}
                  {errors.key && (
                    <span className="text text-danger">
                      {errors.key.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="key"
                  onChange={handleChange}
                  className={`form-control ${errors.key && "is-invalid"}`}
                  {...register("key", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  az{" "}
                  {errors.az && (
                    <span className="text text-danger">
                      {errors.az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="az"
                  onChange={handleChange}
                  className={`form-control ${errors.az && "is-invalid"}`}
                  {...register("az", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>

              <div className="form-group">
                <label>
                  en{" "}
                  {errors.en && (
                    <span className="text text-danger">
                      {errors.en.message}
                    </span>
                  )}{" "}
                </label>
                <input
                  type="text"
                  name="en"
                  onChange={handleChange}
                  className={`form-control ${errors.en && "is-invalid"}`}
                  {...register("en", {
                    required: "Boş ola bilməz.",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(create)}>
            Əlavə et
          </Button>
          <Button className="btn-danger" onClick={() => toggleCreateModal()}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Translates;
