import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "../assets/scss/login.scss";
import logo from "../assets/img/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useUser } from "../services/user-context";
import LoginService from "services/loginService";
const Login = () => {
  const history = useHistory();
  const { login, user, logout } = useUser();
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    const newValue = value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await LoginService.login(formData);
      if (response?.statusCode === 200) {
        setMessage("");
        login(response.result);
        localStorage.setItem("user", JSON.stringify(response.result));
        window.location.href = "/";
      } else {
        setMessage(response.message);
      }
    } catch (error) {
      console.error("API error Login", error);
    }
  };
  return (
    <>
      <Container>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          id="login-box"
        >
          <div className="logo d-flex justify-content-center">
            <img
              width="100"
              src={logo}
              className="img img-responsive img-circle center-block"
            />
          </div>
          {message && (
            <div className="badge w-100 badge-danger p-3 mt-3"> {message}</div>
          )}
          <form onSubmit={loginUser}>
            <div className="controls">
              <input
                required
                type="text"
                name="username"
                onChange={onChange}
                autoComplete="username"
                placeholder="username"
                className="form-control "
              />
              <input
                required
                type="password"
                name="password"
                autoComplete="current-password"
                onChange={onChange}
                placeholder="password"
                className="form-control my-3"
              />

              <button
                type="submit"
                className="btn btn-default btn-block btn-custom"
              >
                Daxil ol
              </button>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Login;
