import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "flowbite-react";
import ReactQuill from "react-quill";
import { Button, Card, Row, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import routes from "routes";
import qmodules from "qmodules";
import qformats from "qformats";
import NewsService from "services/newsService";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const News = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();

  const [data, setData] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false)
  const [deletedData, setDeletedData] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [submitted, setSubmitted] = useState(true);
  // const [doc, setDoc] = useState(null);
  // const [newDoc, setNewDoc] = useState(null);
  const [formData, setFormData] = useState({
    header_az: "",
    header_en: "",
    description_az: "",
    description_en: "",
    image: null,
    document: null,
    createDate: '',
    id: "",
    isAnnouncement: false,
    isConferences: false,
    isInternationalRelations: false,
    isLeisureTime: false,
    isNews: true,
    isPersonalDevelopment: false,
    isProtectRight: false,
    isSocialAssistance: false
  });

  const [openModal, setOpenModal] = useState(false);
  const [createModalShower, setOpenCreateModal] = useState(false);

  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  const LoadData = async () => {
    try {
      const data = await NewsService.getNews();
      setData(data?.result);
    } catch (error) {
      console.error("API error News", error);
    }
  };


  const LoadDeletedData = async () => {
    try {
      const data = await NewsService.getDeletedNews();
      setDeletedData(data?.result);
    } catch (error) {
      console.error("API error News", error);
    }
  };


  const showDeletedDatas = async () => {
    setShowDeleted(!showDeleted)
    LoadDeletedData();
  }
  useEffect(() => {
    LoadData();
  }, []);

  const getData = (item) => {
    reset();
    setMainAditionalPhotos("");
    setOpenModal(true);
    setPhoto(item.photo);
    setPhotos(item.photos);
    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
    }

    setFormData((data) => ({
      ...item,
      createDate: dayjs(item.createDate).format("DD MMM YYYY")
    }));


  };


  const toggleCreateModal = () => {
    setShowDeleted(false)
    reset();
    resetForms();
    setOpenCreateModal(!createModalShower);
  };

  const resetForms = () => {
    setPhoto(null);
    setNewPhoto(null);
    setFormData({
      description_az: "",
      description_en: "",
      header_az: "",
      header_en: "",
      image: null,
      images: null,
      document: null,
      createDate: '',
      id: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue = type === "file" ? files[0] : type === "checkbox" ? checked : value;

    if (type === "file" && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (name == "document") {
          setNewDoc(e.target.result);
        }
        else {
          setNewPhoto(e.target.result);
        }
      };

      reader.readAsDataURL(files[0]);
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });

    setValue(name, newValue);
  };
  const [additionalPhotos, setMainAditionalPhotos] = useState({});
  const setImages = async (e) => {
    const { name, value, type, files } = e.target;
    const newValue = files;

    setMainAditionalPhotos(newValue);
  };
  const quillOnChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeTextEditor = (value, name) => {
    setValue(name, value)
  };

  // create
  const create = async (request) => {


    for (const [key, value] of Object.entries(request)) {
      if (!value || typeof value === "object" || typeof value === "boolean") continue;
      formData[key] = value == 'on' ? true : value == 'off' ? false : value.trim();
    }
    if (isValid) {
      if (submitted) {
        setSubmitted(false);
        try {
          const response = await NewsService.create(formData);
          if (response?.statusCode === 200) {
            setFormData((data) => ({
              ...data,
              id: response?.result,
            }));
            if (response.result) {
              createPhoto(response.result);
              createPhotos(response.result);
              // createDoc(response.result);
            }
          }
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    }
  };
  const createPhoto = async (id) => {
    let data = new FormData();
    data.append("IsHeadImage", true);
    data.append("photo", formData.image);
    if (formData.image) {
      const response = await NewsService.setPhoto(id, data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
        resetForms();
        setOpenCreateModal(false);
      } else {
        toast.success(response?.message);
      }
      setSubmitted(true);
    }
  };
  const createPhotos = async (id) => {

    if (!additionalPhotos.length) return;
    for (const file of additionalPhotos) {
      let data = new FormData();
      data.append("photo", file);
      if (file) {
        const response = await NewsService.setPhoto(id, data);
        setSubmitted(true);
      }
    }
    resetForms();
    LoadData();
  };

  // const createDoc = async (id) => {
  //   let data = new FormData();
  //   data.append("document", formData.document);
  //   if (formData.document) {
  //     const response = await NewsService.addDoc(id, data);
  //     if (response?.statusCode === 200) {
  //       LoadData();
  //       toast.success(response?.message);
  //       resetForms();
  //       setOpenCreateModal(false);
  //     } else {
  //       toast.success(response?.message);
  //     }
  //     setSubmitted(true);
  //   }
  // };

  // const editDoc = async (id) => {
  //   let data = new FormData();
  //   data.append("id", id);
  //   data.append("document", formData.document);
  //   if (formData.document) {
  //     const response = await NewsService.editDoc(data);
  //     if (response?.statusCode === 200) {
  //       LoadData();
  //       setNewDoc(null);
  //       toast.success(response?.message);
  //     } else {
  //       toast.error(response?.message);
  //     }
  //   } else {
  //     toast.error("Şəkil əlavə edin");
  //   }
  // };


  // edit
  const edit = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      if (!value || typeof value === "object" || typeof value === "boolean") continue;
      formData[key] = value == 'on' ? true : value == 'off' ? false : value.trim();
    }

    const response = await NewsService.edit(formData);
    if (response?.statusCode === 200) {
      LoadData();
      toast.success(response.message);
      createPhotos(request.id);
      setOpenModal(false);
      setFormData([]);
    } else {
      toast.error(response.message);
    }
  };

  const editPhoto = async (id) => {
    let data = new FormData();
    data.append("imageId", id);
    data.append("photo", formData.image);
    if (formData.image) {
      const response = await NewsService.editPhoto(data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } else {
      toast.error("Şəkil əlavə edin");
    }
  };

  const deleteData = async (id) => {
    const response = await NewsService.delete(id);
    if (response?.statusCode === 200) {
      LoadData();
      resetForms();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };
  const deletePhoto = async (photo, photos) => {
    try {
      const response = await NewsService.delPhoto(photo.id);

      if (response?.statusCode === 200) {
        toast.success(response?.message);
        const indexToRemove = photos.findIndex((p) => p.id === photo.id);

        if (indexToRemove !== -1) {
          const updatedPhotos = [...photos];
          updatedPhotos.splice(indexToRemove, 1);
          setPhotos(updatedPhotos)
        }
        LoadData();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }
  const unDelete = async (data) => {
    const response = await NewsService.undelete(data.id);
    if (response?.statusCode === 200) {
      setShowDeleted(false)
      LoadData();
      resetForms();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };


  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => toggleCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <button
          onClick={() => showDeletedDatas(true)}
          className={`btn ml-3 ${showDeleted ? "btn-danger" : "btn-secondary "}`}
        > Silinmiş
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Şəkil</th>
              <th className="border-0">Başlıq</th>
              <th className="border-0">Sənəd</th>
              <th className="border-0">Tarix</th>
              <th className="border-0"></th>
            </tr>
          </thead>

          {!showDeleted && (
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img width="150px" src={item?.photo?.photoName} />
                  </td>
                  <td>{item.header_az}</td>
                  <td>
                    {item?.document?.name && (

                      <a target="_blank" className="text text-info" download href={item.document.name}>
                        <i className="fa fa-file"></i>
                        Sənəd
                      </a>
                    )}
                  </td>
                  <td>{dayjs(item.createDate).format("DD MMM YYYY")}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {showDeleted && (
            <tbody>
              {deletedData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <img width="150px" src={item?.photo} />
                  </td>
                  <td>{item.header}</td>
                  <td>
                    {item?.document?.name && (

                      <a target="_blank" className="text text-info" download href={item.document.name}>
                        <i className="fa fa-file"></i>
                        Sənəd
                      </a>
                    )}
                  </td>
                  <td>{dayjs(item.createDate).format("DD MMM YYYY")}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => unDelete(item)}>
                        Geri qaytar
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

        </Table>
        {/* edit */}
        <Modal
          className="myModal"
          show={openModal}
          size="13xl"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              {/* photo */}
              <div className="col-md-6  mb-2 d-flex">
                <div className="form-group">
                  <label>
                    Şəkil{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newPhoto && photo && (
                    <img width="250" src={photo.photoName} alt="Preview" />
                  )}
                  {newPhoto && <img width="250" src={newPhoto} alt="Preview" />}
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChange(e);
                      register("image");
                    }}
                    className={`form-control`}
                  />
                  {/* edit photo button */}
                  {newPhoto && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editPhoto(photo.photoId)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div>
                <div className="form-group">

                  {/* Xəbər */}
                  <div>
                    <input
                      type="checkbox"
                      name="isNews"
                      id="isNews"
                      checked={formData.isNews}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isNews && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isNews" className="cp">
                      Xəbər{" "}
                    </label>
                  </div>

                  {/* elan */}
                  <div>
                    <input
                      type="checkbox"
                      name="isAnnouncement"
                      id="isAnnouncement"
                      checked={formData.isAnnouncement}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isAnnouncement && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isAnnouncement">
                      Elan{" "}
                    </label>
                  </div>

                  {/* internalcom */}
                  <div>
                    <input
                      type="checkbox"
                      name="isInternationalRelations"
                      id="isInternationalRelations"
                      checked={formData.isInternationalRelations}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isInternationalRelations && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isInternationalRelations" className="cp">
                      Beynəlxalq Əlaqələr{" "}
                    </label>
                  </div>

                  {/* seminar */}
                  <div>
                    <input
                      type="checkbox"
                      name="isConferences"
                      id="isConferences"
                      checked={formData.isConferences}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isConferences && "is-invalid"
                        }`}

                    />
                    <label htmlFor="isConferences" className="cp">
                      Konferans və seminar{" "}
                    </label>
                  </div>

                  {/* Şəxsi İnkişaf */}
                  <div>
                    <input
                      type="checkbox"
                      name="isPersonalDevelopment"
                      id="isPersonalDevelopment"
                      checked={formData.isPersonalDevelopment}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isPersonalDevelopment && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isPersonalDevelopment" className="cp">
                      Şəxsi İnkişaf{" "}
                    </label>
                  </div>

                  {/* isProtectRight */}
                  <div>
                    <input
                      type="checkbox"
                      name="isProtectRight"
                      id="isProtectRight"
                      checked={formData.isProtectRight}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isProtectRight && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isProtectRight" className="cp">
                      Hüquqların mühafizəsi{" "}
                    </label>
                  </div>
                  {/* asudə */}
                  <div>
                    <input
                      type="checkbox"
                      name="isLeisureTime"
                      id="isLeisureTime"
                      checked={formData.isLeisureTime}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isLeisureTime && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isLeisureTime" className="cp">
                      Asudə vaxt{" "}
                    </label>
                  </div>
                  {/* isSocialAssistance */}
                  <div>
                    <input
                      type="checkbox"
                      name="isSocialAssistance"
                      checked={formData.isSocialAssistance}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isSocialAssistance && "is-invalid"
                        }`}
                    />
                    <label className="cp" htmlFor="isSocialAssistance">
                      Sosial Yardım{" "}
                    </label>
                  </div>
                </div>
              </div>

              {/* photos */}
              <div className="col-md-6  mb-2">
                {/* <div className="form-group">
                  <label>
                    {" "}
                    {errors.document && (
                      <span className="text text-danger p-3">
                        {errors.document.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newDoc && (
                    <a
                      target="_blank"
                      className="text text-info"
                      href={doc?.name}
                    >
                      <i className="fa fa-file"></i>
                      Sənəd
                    </a>
                  )}
                  {newDoc && (
                    <a className="text text-info" href={newDoc}>
                      <i className="fa fa-file"></i>
                      Yeni Sənəd
                    </a>
                  )}
                  <input
                    type="file"
                    name="document"
                    onChange={(e) => {
                      handleChange(e);
                      register("document");
                    }}
                    className={`form-control`}
                  />
                  {newDoc && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editDoc(doc.id)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div> */}
                <div className="form-group">
                  <label>
                    Şəkillər{" "}
                    {errors.images && (
                      <span className="text text-danger p-3">
                        {errors.images.message}
                      </span>
                    )}{" "}
                  </label>

                  <input
                    type="file"
                    name="images"
                    multiple
                    accept="image/*"
                    onChange={(e) => setImages(e)}
                    className={`form-control ${errors.images && "is-invalid"}`}
                  />
                  <ul className="d-flex mt-3">
                    {photos && photos.map((item, index) => (
                      <li className="mr-4 shadow position-relative" key={index}>
                        <img width="60px" src={item.link} />
                        <span onClick={() => deletePhoto(item, photos)} className="delete-photo position-absolute">x</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="form-group">
                  <label>
                    Tarix {formData.createDate}

                  </label>
                  <input
                    type="date"
                    name="createDate"
                    onChange={handleChange}
                    className={`form-control ${errors.createDate && "is-invalid"
                      }`}

                  />
                </div>
              </div>

              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.header_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="header_az"
                    onChange={handleChange}
                    className={`form-control ${errors.header_az && "is-invalid"
                      }`}
                    {...register("header_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.header_en && (
                      <span className="text text-danger">
                        {errors.header_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="header_en"
                    onChange={handleChange}
                    className={`form-control ${errors.header_en && "is-invalid"
                      }`}
                    {...register("header_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>

              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir az </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_az}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir en </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_en}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(edit)}>
              Dəyiş
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModal(false)}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>

        {/* create */}
        <Modal
          className="myModal"
          show={createModalShower}
          size="13xl"
          onClose={() => toggleCreateModal()}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              {/* image */}
              <div className="col-md-6  mb-2 d-flex">
                <div className="form-group">
                  <label>
                    Şəkil{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}
                  </label>

                  {newPhoto && <img width="200" src={newPhoto} alt="Preview" />}

                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChange(e);
                      register("image");
                    }}
                    className={`form-control ${errors.image && "is-invalid"}`}
                  />
                </div>
                <div className="form-group">

                  {/* Xəbər */}
                  <div>
                    <input
                      type="checkbox"
                      name="isNews"
                      checked={formData.isNews}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isNews && "is-invalid"
                        }`}
                    />
                    <label>
                      Xəbər{" "}
                    </label>
                  </div>

                  {/* elan */}
                  <div>

                    <input
                      type="checkbox"
                      name="isAnnouncement"
                      checked={formData.isAnnouncement}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isAnnouncement && "is-invalid"
                        }`}
                    />
                    <label>
                      Elan{" "}
                    </label>
                  </div>

                  {/* internalcom */}
                  <div>
                    <input
                      type="checkbox"
                      name="isInternationalRelations"
                      checked={formData.isInternationalRelations}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isInternationalRelations && "is-invalid"
                        }`}
                    />
                    <label>
                      Beynəlxalq Əlaqələr{" "}
                    </label>
                  </div>

                  {/* seminar */}
                  <div>
                    <input
                      type="checkbox"
                      name="isConferences"
                      checked={formData.isConferences}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isConferences && "is-invalid"
                        }`}

                    />
                    <label>
                      Konferans və seminar{" "}
                    </label>
                  </div>

                  {/* Şəxsi İnkişaf */}
                  <div>
                    <input
                      type="checkbox"
                      name="isPersonalDevelopment"
                      checked={formData.isPersonalDevelopment}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isPersonalDevelopment && "is-invalid"
                        }`}
                    />
                    <label>
                      Şəxsi İnkişaf{" "}
                    </label>
                  </div>

                  {/* isProtectRight */}
                  <div>
                    <input
                      type="checkbox"
                      name="isProtectRight"
                      checked={formData.isProtectRight}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isProtectRight && "is-invalid"
                        }`}
                    />
                    <label>
                      Hüquqların mühafizəsi{" "}
                    </label>
                  </div>
                  {/* asudə */}
                  <div>
                    <input
                      type="checkbox"
                      name="isLeisureTime"
                      checked={formData.isLeisureTime}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isLeisureTime && "is-invalid"
                        }`}
                    />
                    <label>
                      Asudə vaxt{" "}
                    </label>
                  </div>
                  {/* isSocialAssistance */}
                  <div>

                    <input
                      type="checkbox"
                      name="isSocialAssistance"
                      checked={formData.isSocialAssistance}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isSocialAssistance && "is-invalid"
                        }`}
                    />
                    <label>
                      Sosial Yardım{" "}
                    </label>
                  </div>
                </div>
              </div>
              {/* photos */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Şəkillər{" "}
                    {errors.images && (
                      <span className="text text-danger p-3">
                        {errors.images.message}
                      </span>
                    )}
                  </label>

                  <input
                    type="file"
                    name="images"
                    multiple
                    accept="image/*"
                    onChange={(e) => setImages(e)}
                    className={`form-control ${errors.image && "is-invalid"}`}
                  />
                </div>
                {/* <div className="form-group">
                  <label>
                    Sənəd{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}
                  </label>

                  <input
                    type="file"
                    name="document"
                    onChange={(e) => {
                      handleChange(e);
                      register("document");
                    }}
                    className={`form-control ${errors.document && "is-invalid"
                      }`}
                    {...register("document", {})}
                  />
                </div> */}

                <div className="form-group">
                  <label>
                    Tarix{" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.createDate.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="date"
                    name="createDate"
                    onChange={handleChange}
                    className={`form-control ${errors.createDate && "is-invalid"
                      }`}
                    {...register("createDate", {
                      required: "Tarix əlavə edin",
                    })}
                  />
                </div>
              </div>

              {/* header */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.header_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="header_az"
                    onChange={handleChange}
                    className={`form-control ${errors.header_az && "is-invalid"
                      }`}
                    {...register("header_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.header_en && (
                      <span className="text text-danger">
                        {errors.header_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="header_en"
                    onChange={handleChange}
                    className={`form-control ${errors.header_en && "is-invalid"
                      }`}
                    {...register("header_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>

              {/* desc */}
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>
                    Təsvir (az){" "}
                    {errors.description_az && (
                      <span className="text text-danger">
                        {errors.description_az.message}
                      </span>
                    )}{" "}
                  </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    name="description_az"
                    value={formData.description_az}
                    onChange={(value, name) =>
                      quillOnChange(value, "description_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir (en) </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    name="description_en"
                    value={formData.description_en}
                    onChange={(value, name) =>
                      quillOnChange(value, "description_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(create)}>
              Əlavə et
            </Button>
            <Button className="btn-danger" onClick={() => toggleCreateModal()}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </>
  );
};

export default News;
