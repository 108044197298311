import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, Row } from "react-bootstrap";
import { Modal } from "flowbite-react";
import routes from "../routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import UserService from "services/userService";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Users = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const [users, setUsers] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [createModalShower, setOpenCreateModal] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    username: "",
    password: "",
  });

  const location = useLocation();
  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ||
    {};

  const [openModal, setOpenModal] = useState(false);
  const [passModal, setPassModal] = useState(false);

  const loadUsers = async () => {
    try {
      const data = await UserService.getUsers();
      setUsers(data?.result);
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue.trim(),
    });
  };

  const getData = (item) => {
    reset();
    setOpenModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }
  };

  const getUserData = (item) => {
    reset();
    setPassModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setCurrentData({});
  };

  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    if (isValid) {
      try {
        const response = await UserService.create(formData);
        if (response?.statusCode === 200) {
          loadUsers();
          toast.success(response.message);
          toggleCreateModal();
          setFormData({});
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error creating user:", error);
      }
    }
  };

  // edit
  const edit = async (request) => {
    for (const [key, value] of Object.entries(formData)) {
      formData[key] = value;
    }

    try {
      const response = await UserService.edit(formData);
      if (response?.statusCode === 200) {
        loadUsers();
        toast.success(response.message);
        onCloseModal();
        setFormData({});
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };
  const toggleCreateModal = () => {
    setOpenCreateModal(false);
    reset();
  };
  const togglePassModal = () => {
    setPassModal(false);
    reset();
  };

  const deleteData = async (id) => {
    try {
      const response = await UserService.delete(id);
      if (response?.statusCode === 200) {
        loadUsers();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const changePass = async (request) => {
    try {
      const data = {
        id: request.id,
        password: request.password,
      };

      const response = await UserService.changePass(data);
      if (response?.statusCode === 200) {
        loadUsers();
        togglePassModal();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => setOpenCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">Ad soyad</th>
              <th className="border-0">İstifadəçi adı</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {users
              ?.sort((a, b) => a.az.localeCompare(b.az))
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.username}</td>
                  <td>{item.fullname}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => getUserData(item)}>
                        Şifrə dəyiş
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>

      {/* edit */}
      <Modal
        className="myModal"
        show={openModal}
        size="sm"
        onClose={() => onCloseModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Istifadəçi adı{" "}
                  {errors.username && (
                    <span className="text text-danger">
                      {errors.username.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="username"
                  onChange={handleChange}
                  className={`form-control ${errors.username && "is-invalid"}`}
                  {...register("username", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad soyad{" "}
                  {errors.fullname && (
                    <span className="text text-danger">
                      {errors.fullname.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname && "is-invalid"}`}
                  {...register("fullname", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={edit}>
            Dəyiş
          </Button>
          <Button className="btn-danger" onClick={() => onCloseModal(false)}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create */}
      <Modal
        className="myModal"
        show={createModalShower}
        size="xl"
        onClose={() => toggleCreateModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  İstifadəçi adı{" "}
                  {errors.username && (
                    <span className="text text-danger">
                      {errors.username.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="username"
                  onChange={handleChange}
                  className={`form-control ${errors.username && "is-invalid"}`}
                  {...register("username", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message:
                        "İstifadəçi adı min. 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad soyad{" "}
                  {errors.fullname && (
                    <span className="text text-danger">
                      {errors.fullname.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname && "is-invalid"}`}
                  {...register("fullname", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 7,
                      message: "Minimum 7 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>

              <div className="form-group">
                <label>
                  Şifrə{" "}
                  {errors.password && (
                    <span className="text text-danger">
                      {errors.password.message}
                    </span>
                  )}{" "}
                </label>
                <input
                  type="text"
                  name="password"
                  onChange={handleChange}
                  className={`form-control ${errors.password && "is-invalid"}`}
                  {...register("password", {
                    required: "Boş ola bilməz.",
                    minLength: {
                      value: 8,
                      message: "Minimum 8 simvoldan ibarət olmalıdır.",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                      message:
                        "Şifrə böyük hərf, kiçik hərf, rəqəm və xüsusi simvol içərməlidir.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(create)}>
            Əlavə et
          </Button>
          <Button className="btn-danger" onClick={() => toggleCreateModal()}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* change pass */}
      <Modal
        className="myModal"
        show={passModal}
        size="xl"
        onClose={() => togglePassModal()}
      >
        <Modal.Header className="p-3"> {formData.fullname} </Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Yeni Şifrə{" "}
                  {errors.password && (
                    <span className="text text-danger">
                      {errors.password.message}
                    </span>
                  )}{" "}
                </label>
                <input
                  type="text"
                  name="password"
                  onChange={handleChange}
                  className={`form-control ${errors.password && "is-invalid"}`}
                  {...register("password", {
                    required: "Boş ola bilməz.",
                    minLength: {
                      value: 8,
                      message: "Minimum 8 simvoldan ibarət olmalıdır.",
                    },
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                      message:
                        "Şifrə böyük hərf, kiçik hərf, rəqəm və xüsusi simvol içərməlidir.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(changePass)}>
             Dəyiş
          </Button>
          <Button className="btn-danger" onClick={() => toggleCreateModal()}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
