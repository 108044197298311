import { get, post, del, put } from "./api-service";

const StructureService = {


  
  getExecutors: async () => {
    try {
      const response = await get("admin/executor/getall").catch((error) =>
        console.error(error)
      );
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    return await post("admin/executor", request);
  },
  edit: async (request) => {
    return await put("admin/executor", request);
  },
  delete: async (request) => {
    return await del("admin/executor/" + request, "");
  },
};



export default StructureService;
