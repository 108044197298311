import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import { Modal } from "flowbite-react";
import routes from "../routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import qmodules from "qmodules";
import qformats from "qformats";
import StructuresService from "services/structureService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Structures = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const [structures, setStructures] = useState([]);
  const [createModalShower, setOpenCreateModal] = useState(false);
  const [formData, setFormData] = useState({
    name_en: "",
    name_az: "",
    description_en: "",
    description_az: "",
    image: "",
    id: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const loadStructures = async () => {
    const data = await StructuresService.getStructures();
    setStructures(data?.result);
  };
  useEffect(() => {
    loadStructures();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const getData = (item) => {
    setOpenModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };
  const changeTextEditor = (value, name) => {
    setValue(name, value)
  };
  const location = useLocation();
  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    console.log(formData)
    if (isValid) {
      try {
        const response = await StructuresService.create(formData);
        if (response?.statusCode === 200) {
          loadStructures();
          toast.success(response.message);
          toggleCreateModal();
          reset()
          setFormData([]);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  // edit
  async function edit(request) {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    if (isValid) {
      try {
        const response = await StructuresService.edit(formData);
        if (response?.statusCode === 200) {
          loadStructures();
          toast.success(response.message);
          onCloseModal();
          setFormData([]);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }

  const toggleCreateModal = () => {
    setOpenCreateModal(false);
  };

  const deleteData = async (id) => {
    const response = await StructuresService.delete(id);
    if (response?.statusCode === 200) {
      loadStructures();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => setOpenCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">Ad (az)</th>
              <th className="border-0">Ad (en)</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {structures
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.name_az}</td>
                  <td>{item.name_en}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>

      {/* edit */}
      <Modal
        className="myModal"
        show={openModal}
        size="7xl"
        onClose={() => onCloseModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Ad (az){" "}
                  {errors.name_az && (
                    <span className="text text-danger">
                      {errors.name_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="name_az"
                  onChange={handleChange}
                  className={`form-control ${errors.name_az && "is-invalid"}`}
                  {...register("name_az", {
                    required: "Ad boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Ad minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad (en){" "}
                  {errors.name_en && (
                    <span className="text text-danger">
                      {errors.name_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="name_en"
                  onChange={handleChange}
                  className={`form-control ${errors.name_en && "is-invalid"}`}
                  {...register("name_en", {
                    required: "Boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Başlıq minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>

            </Col>
            <div className="col-md-6 mb-5">
              <div className="form-group">
                <label>Təsvir az </label>
                <ReactQuill
                  theme="snow"
                  modules={qmodules}
                  formats={qformats}
                  value={formData.description_az}
                  onChange={(value, name) =>
                    changeTextEditor(value, "description_az")
                  }
                  placeholder="write your content ...."
                  style={{ height: "150px" }}
                ></ReactQuill>
              </div>
            </div>
            <div className="col-md-6 mb-5">
              <div className="form-group">
                <label>Təsvir en </label>
                <ReactQuill
                  theme="snow"
                  modules={qmodules}
                  formats={qformats}
                  value={formData.description_en}
                  onChange={(value, name) =>
                    changeTextEditor(value, "description_en")
                  }
                  placeholder="write your content ...."
                  style={{ height: "150px" }}
                ></ReactQuill>
              </div>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(edit)}>
            Dəyiş
          </Button>
          <Button className="btn-danger" onClick={() => onCloseModal(false)}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create */}
      <Modal
        className="myModal"
        show={createModalShower}
        size="7xl"
        onClose={() => toggleCreateModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Ad (az){" "}
                  {errors.name_az && (
                    <span className="text text-danger">
                      {errors.name_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="name_az"
                  onChange={handleChange}
                  className={`form-control ${errors.name_az && "is-invalid"}`}
                  {...register("name_az", {
                    required: "Ad boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Ad minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad (en){" "}
                  {errors.name_en && (
                    <span className="text text-danger">
                      {errors.name_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="name_en"
                  onChange={handleChange}
                  className={`form-control ${errors.name_en && "is-invalid"}`}
                  {...register("name_en", {
                    required: "Ad boş ola bilməz",
                    minLength: {
                      value: 3,
                      message: "Ad minimum 3 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>

            </Col>
            <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir az </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_az}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir en </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_en}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(create)}>
            Əlavə et
          </Button>
          <Button className="btn-danger" onClick={() => toggleCreateModal()}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Structures;
