import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "flowbite-react";
import ReactQuill from "react-quill";
import { Button, Card, Row, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import routes from "routes";
import qmodules from "qmodules";
import qformats from "qformats";
import parse from "html-react-parser";
import BoardService from "services/boardService";
import { toast } from "react-toastify";
const Board = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();

  const [data, setData] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [newPhoto, setNewPhoto] = useState(null);
  const [submitted, setSubmitted] = useState(true);

  const [deletedData, setDeletedData] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  const [formData, setFormData] = useState({
    fullname_az: "",
    fullname_en: "",
    position_az: "",
    position_en: "",
    description_az: "",
    description_en: "",
    image: null,
    order: 0,
    isCheck: true,
    id: "",
  });



  const [openModal, setOpenModal] = useState(false);
  const [createModalShower, setOpenCreateModal] = useState(false);

  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  const LoadData = async () => {
    try {
      const data = await BoardService.getBoard(false);
      setData(data?.result);
    } catch (error) {
      console.error("API error Board", error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const getData = (item) => {
    reset();
    setOpenModal(true);
    setPhoto(item.photo);
    for (const [key, value] of Object.entries(item)) {

      setValue(key, value);
    }

    setFormData({
      ...formData,
      description_az: item.description_az,
      description_en: item.description_en,
    });
  };

  const toggleCreateModal = () => {
    reset();
    resetForms();
    setOpenCreateModal(!createModalShower);
  };

  const resetForms = () => {
    setPhoto(null);
    setNewPhoto(null);
    setFormData({
      description_az: "",
      description_en: "",
      position_az: "",
      position_en: "",
      fullname_az: "",
      fullname_en: "",
      order: 0,
      isCheck: false,
      image: null,
      id: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    if (type === "file" && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setNewPhoto(e.target.result);
      };

      reader.readAsDataURL(files[0]);
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });
    setValue(name, newValue);

    if (name === "isCheck") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
      setValue(name, checked);
    }
  };

  const quillOnChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeTextEditor = (value, name) => {
    setValue(name, value)
  };

  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      if (key !== "image" && key !== "isCheck" && key !== "photo" && key !== "photos") {
        formData[key] = value.trim();
      }
      else if (key === "isCheck" || key === "order") {
        formData[key] = value;
      }
      else {
        formData[key] = value[0];
      }
    }

    if (isValid) {
      if (submitted) {
        setSubmitted(false);
        try {
          formData.isCheck = false;
          const response = await BoardService.create(formData);
          if (response?.statusCode === 200) {
            setFormData((data) => ({
              ...data,
              id: response?.result,
            }));
            if (response.result) {
              createPhoto(response.result);
            }
          }
        } catch (error) {
          setSubmitted(true)
          console.error("Error submitting form:", error);
        }
      }
    }
  };
  const createPhoto = async (id) => {
    let data = new FormData();
    data.append("image", formData.image);
    if (formData.image) {
      const response = await BoardService.setPhoto(id, data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
        resetForms();
        setOpenCreateModal(false);
      } else {
        toast.success(response?.message);
      }
    }
    setSubmitted(true);
  };

  // edit
  const edit = async (request) => {


    for (const [key, value] of Object.entries(request)) {
      if (key !== "image" && key !== "photos" && key !== "isCheck" && key !== "photo" && key !== "order") {
        formData[key] = value.trim();
      }
      if (key === "order") {
        formData[key] = value;
      }
    }
    formData.isCheck = false;
    const response = await BoardService.edit(formData);
    if (response?.statusCode === 200) {
      LoadData();
      toast.success(response.message);
      setOpenModal(false);
      setFormData([]);
    } else {
      toast.error(response.message);
    }
  };

  const editPhoto = async (id) => {
    let data = new FormData();
    data.append("imageId", id);
    data.append("photo", formData.image);
    if (formData.image) {
      const response = await BoardService.editPhoto(data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } else {
      toast.error("Şəkil əlavə edin");
    }
  };

  const deleteData = async (id) => {
    const response = await BoardService.delete(id);
    if (response?.statusCode === 200) {
      LoadData();
      resetForms();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const LoadDeletedData = async () => {
    try {
      const data = await BoardService.getDeletedData();
      setDeletedData(data?.result.filter(x=>x.isCheck==false));
    } catch (error) {
      console.error("API error News", error);
    }
  };

  const showDeletedDatas = async () => {
    setShowDeleted(!showDeleted)
    LoadDeletedData();
  }

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => toggleCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <button
          onClick={() => showDeletedDatas(true)}
          className={`btn ml-3 ${showDeleted ? "btn-danger" : "btn-secondary "}`}
        > Silinmiş
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Şəkil</th>
              <th className="border-0">Ad</th>
              <th className="border-0">Vəzifə</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          {!showDeleted && (
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{item.order}</td>
                  <td>
                    <img width="100px" src={item?.photo?.photoName} />
                  </td>
                  <td className="w-25">{item.fullname_az}</td>
                  <td className="w-25">{item.position_az}</td>

                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
             {showDeleted && (
            <tbody>
              {deletedData?.map((item, index) => (
                <tr key={index}>
                  <td>{item.order}</td>
                  <td>
                    <img width="100px" src={item?.photo?.photoName} />
                  </td>
                  <td className="w-25">{item.fullname}</td>
                  <td className="w-25">{item.position}</td>

                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {/* edit */}
        <Modal
          className="myModal"
          show={openModal}
          size="7xl"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Şəkil{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newPhoto && photo && (
                    <img width="250" src={photo.photoName} alt="Preview" />
                  )}
                  {newPhoto && <img width="250" src={newPhoto} alt="Preview" />}
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChange(e);
                      register("image");
                    }}
                    className={`form-control`}
                  />
                  {/* edit photo button */}
                  {newPhoto && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editPhoto(photo.photoId)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Sıra{" "}
                    {errors.order && (
                      <span className="text text-danger">
                        {errors.order.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    name="order"
                    onChange={handleChange}
                    className={`form-control ${errors.order && "is-invalid"
                      }`}
                    {...register("order", {
                      required: "Sıra əlavə edin",
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.fullname_az && (
                      <span className="text text-danger">
                        {errors.fullname_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="fullname_az"
                    onChange={handleChange}
                    className={`form-control ${errors.fullname_az && "is-invalid"
                      }`}
                    {...register("fullname_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.fullname_en && (
                      <span className="text text-danger">
                        {errors.fullname_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="fullname_en"
                    onChange={handleChange}
                    className={`form-control ${errors.fullname_en && "is-invalid"
                      }`}
                    {...register("fullname_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Vəzifə (az){" "}
                    {errors.position_az && (
                      <span className="text text-danger">
                        {errors.position_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="position_az"
                    onChange={handleChange}
                    className={`form-control ${errors.position_az && "is-invalid"
                      }`}
                    {...register("position_az", {
                      required: "Vəzifə əlavə edin",
                      minLength: {
                        value: 4,
                        message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Vəzifə (en){" "}
                    {errors.position_en && (
                      <span className="text text-danger">
                        {errors.position_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="position_en"
                    onChange={handleChange}
                    className={`form-control ${errors.position_en && "is-invalid"
                      }`}
                    {...register("position_en", {
                      required: "Vəzifə əlavə edin",
                      minLength: {
                        value: 4,
                        message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir az </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_az}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir en </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.description_en}
                    onChange={(value, name) =>
                      changeTextEditor(value, "description_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(edit)}>
              Dəyiş
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModal(false)}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>

        {/* create */}
        <Modal
          className="myModal"
          show={createModalShower}
          size="7xl"
          onClose={() => toggleCreateModal()}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              {/* image */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Şəkil{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}
                  </label>
                  {photo && (
                    <img width="150" src={photo.photoName} alt="Preview" />
                  )}

                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChange(e);
                      register("image");
                    }}
                    className={`form-control ${errors.image && "is-invalid"}`}
                    {...register("image", {})}
                  />
                </div>
                {/* order */}
                <div className="form-group">
                  <label>
                    Sıra (az){" "}
                    {errors.order && (
                      <span className="text text-danger">
                        {errors.order.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="order"
                    onChange={handleChange}
                    className={`form-control ${errors.order && "is-invalid"
                      }`}
                    {...register("order", {
                      required: "Sıra əlavə edin",
                    })}
                  />
                </div>

                {/* checker */}
                {/* <div className="form-group">
                  <label>
                    Nəzarət-təftiş?{" "}
                  </label>
                  <input
                    type="checkbox"
                    name="isCheck"
                    onChange={handleChange}
                    className={`form-control ml-3 ${errors.isCheck && "is-invalid"
                      }`}
                  />
                </div> */}
              </div>

              {/* header */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Ad və Soyad  (az){" "}
                    {errors.fullname_az && (
                      <span className="text text-danger">
                        {errors.fullname_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="fullname_az"
                    onChange={handleChange}
                    className={`form-control ${errors.fullname_az && "is-invalid"
                      }`}
                    {...register("fullname_az", {
                      required: "  Ad və Soyad  əlavə edin",
                      minLength: {
                        value: 6,
                        message: "  Ad və Soyad  minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Ad və Soyad (en){" "}
                    {errors.fullname_en && (
                      <span className="text text-danger">
                        {errors.fullname_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="fullname_en"
                    onChange={handleChange}
                    className={`form-control ${errors.fullname_en && "is-invalid"
                      }`}
                    {...register("fullname_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "  Ad və Soyad  minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Vəzifə (az){" "}
                    {errors.position_az && (
                      <span className="text text-danger">
                        {errors.position_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="position_az"
                    onChange={handleChange}
                    className={`form-control ${errors.position_az && "is-invalid"
                      }`}
                    {...register("position_az", {
                      required: "Vəzifə əlavə edin",
                      minLength: {
                        value: 4,
                        message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Vəzifə (en){" "}
                    {errors.position_en && (
                      <span className="text text-danger">
                        {errors.position_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="position_en"
                    onChange={handleChange}
                    className={`form-control ${errors.position_en && "is-invalid"
                      }`}
                    {...register("position_en", {
                      required: "Vəzifə əlavə edin",
                      minLength: {
                        value: 4,
                        message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>

              {/* desc */}
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>
                    Təsvir (az){" "}
                    {errors.description_az && (
                      <span className="text text-danger">
                        {errors.description_az.message}
                      </span>
                    )}{" "}
                  </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    name="description_az"
                    value={formData.description_az}
                    onChange={(value, name) =>
                      quillOnChange(value, "description_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir (en) </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    name="description_en"
                    value={formData.description_en}
                    onChange={(value, name) =>
                      quillOnChange(value, "description_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(create)}>
              Əlavə et
            </Button>
            <Button className="btn-danger" onClick={() => toggleCreateModal()}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </>
  );
};

export default Board;
