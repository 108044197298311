import { get, post, del, put } from "./api-service";

const TranslateService = {


  
  getTranslates: async () => {
    try {
      const response = await get("admin/translate/getall").catch((error) =>
        console.error(error)
      );
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    return await post("admin/translate", request);
  },
  edit: async (request) => {
    return await put("admin/translate", request);
  },
  delete: async (request) => {
    return await del("admin/translate/" + request, "");
  },
};



export default TranslateService;
