// src/services/boardService.js

import { get, del, post, put } from "./api-service";

const LinkService = {
  getBoard: async () => {
    try {
      const response = await get("admin/link/getall").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    try {
      const response = await post("admin/link", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (request) => {
    try {
      const response = await put("admin/link", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  editPhoto: async (request) => {
    const response = await put("admin/link/photo/", request);
    return response;
  },
  delete: async (request) => {
    try {
      const response = await del("admin/link/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response; 
      return data;
    } catch (error) {
      throw error;
    }
  },
  setPhoto: async (id, request) => {
    try {
      const response = await post("admin/link/add-photo/" + id, request);

      if (response.statusCode !== 200) {
        throw new Error("API error");
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default LinkService;
