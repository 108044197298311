
import Dashboard from "views/Dashboard.js";
// import MainSlider from "views/MainSlider";
import News from "views/News";
import Translates from "views/Translates";
import Gallery from "views/Gallery";
import Common from "views/Common";
import Settings from "views/Settings";
import Board from "views/Board";
import Structures from "views/Structure";
import Notifications from "views/Notifications";
import Users from "views/User";
import Usefuls from "views/Usefuls";
import Executors from "views/Executors";
import Partner from "views/Partner";
import Link from "views/Link";
import Control from "views/Control";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/news",
    name: "Xəbərlər",
    icon: "nc-icon nc-badge",
    component: News,
    layout: "/admin"
  },
  {
    path: "/usefuls",
    name: " Faydalı Məlumatlar",
    icon: "nc-icon nc-notes",
    component: Usefuls,
    layout: "/admin"
  },
  {
    path: "/partners",
    name: "Partnyorlar",
    icon: "nc-icon nc-notes",
    component: Partner,
    layout: "/admin"
  },
  {
    path: "/links",
    name: "Linklər",
    icon: "nc-icon nc-notes",
    component: Link,
    layout: "/admin"
  },
  // {
  //   path: "/slider",
  //   name: "Slider",
  //   icon: "nc-icon nc-button-play",
  //   component: MainSlider,
  //   layout: "/admin"
  // },
  {
    path: "/settings",
    name: "Tənzimləmələr",
    icon: "nc-icon nc-settings-gear-64",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/common",
    name: "Ümumi Səhifələr",
    icon: "nc-icon nc-chart-pie-35",
    component: Common,
    layout: "/admin"
  },
  {
    path: "/translates",
    name: "Tərcümələr",
    icon: "nc-icon nc-chart-pie-35",
    component: Translates,
    layout: "/admin"
  },
  // {
  //   path: "/judges",
  //   name: "Hakimlər",
  //   icon: "nc-icon nc-single-02",
  //   component: Judges,
  //   layout: "/admin"
  // },
  {
    path: "/boards",
    name: "Idarə Heyəti",
    icon: "nc-icon nc-bank",
    component: Board,
    layout: "/admin"
  },
  {
    path: "/controls",
    name: "Nəzarət təftiş",
    icon: "nc-icon nc-bank",
    component: Control,
    layout: "/admin"
  },
  {
    path: "/gallery",
    name: "Qalareya",
    icon: "nc-icon nc-layers-3",
    component: Gallery,
    layout: "/admin"
  },
  {
    path: "/structures",
    name: "Struktur",
    icon: "nc-icon nc-chart-pie-35",
    component: Structures,
    layout: "/admin"
  },
  {
    path: "/executors",
    name: "Komisya",
    icon: "nc-icon nc-chart-pie-35",
    component: Executors,
    layout: "/admin"
  },
  // {
  //   path: "/events",
  //   name: "Tədbirlər",
  //   icon: "nc-icon nc-notification-70",
  //   component: Event,
  //   layout: "/admin"
  // },
  // {
  //   path: "/publications",
  //   name: "Nəşrlər",
  //   icon: "nc-icon nc-notes",
  //   component: Publications,
  //   layout: "/admin"
  // },
 
  // {
  //   path: "/acts",
  //   name: "Normativ Aktlar",
  //   icon: "nc-icon nc-notes",
  //   component: Regulations,
  //   layout: "/admin"
  // },
  {
    path: "/notifications",
    name: "Bildirişlər",
    icon: "nc-icon nc-email-83",
    component: Notifications,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Istifadəçilər",
    icon: "nc-icon nc-circle-09",
    component: Users,
    layout: "/admin"
  },

];

export default dashboardRoutes;
