// src/services/galleryService.js

import { toast } from "react-toastify";
import { get, del, post, put } from "./api-service";

const GalleryService = {
  getGalleries: async () => {
    try {
      const response = await get("admin/gallery/getall").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    try {
      const response = await post("admin/gallery", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (request) => {
    try {
      const response = await put("admin/gallery", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  editPhoto: async (request) => {
    const response = await put("admin/gallery/photo/", request);
    return response;
  },
  delPhoto: async (request) => {
    const response = await del("admin/gallery/remove-photo/" + request, "");
    return response;
  },
  delete: async (request) => {
    try {
      const response = await del("admin/gallery/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response; // Assuming the response is JSON
      return data;
    } catch (error) {
      throw error;
    }
  },
  setPhoto: async (id, request) => {
    try {
      const response = await post("admin/gallery/add-photo/" + id, request);

      if (response.statusCode !== 200) {
        throw new Error("API error");
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default GalleryService;
