import { get, post, del, put } from "./api-service";

const SettingsService = {
  getSettings: async () => {
    return await await get("admin/setting/getall");
  },

  edit: async (request) => {
    return await put("admin/setting", request);
  },
};

export default SettingsService;
