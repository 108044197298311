import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Button, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import { Modal } from "flowbite-react";
import routes from "../routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import qmodules from "qmodules";
import qformats from "qformats";
import ExecutorsService from "services/executorService";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StructureService from "services/structureService";
const Executors = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const [executors, setExecutors] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [createModalShower, setOpenCreateModal] = useState(false);
  const [formData, setFormData] = useState({
    fullname_en: "",
    fullname_az: "",
    fullname_ru: "",
    position_en: "",
    position_az: "",
    position_ru: "",
    structureId: "",
    order: "",
    id: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const loadExecutors = async () => {
    const data = await ExecutorsService.getExecutors();
    setExecutors(data?.result);
  };


  const loadStructures = async () => {
    const data = await StructureService.getStructures();
    setStructures(data?.result);
  };

  useEffect(() => {
    loadExecutors();
    loadStructures();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });

    if (name === "structureId") {
      setCategory(value)
    }
    console.log(formData.structureId)
  };

  const setCategory = (value) => {
    setSelectedCategory(value);
  };

  const getData = (item) => {
    setOpenModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }

    setCategory(item.structureId)
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const location = useLocation();
  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      formData[key] = value.trim();
    }
    if (isValid) {
      try {
        const response = await ExecutorsService.create(formData);
        if (response?.statusCode === 200) {
          loadExecutors();
          toast.success(response.message);
          toggleCreateModal();
          reset()
          setFormData([]);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  // edit
  async function edit(request) {
    for (const [key, value] of Object.entries(request)) {
      if (value && key !== "structure") {
        formData[key] = value.trim();
      }
      if (key === "structureId") {
        formData[key] = selectedCategory
      }
    }
    if (isValid) {
      try {
        const response = await ExecutorsService.edit(formData);
        if (response?.statusCode === 200) {
          loadExecutors();
          toast.success(response.message);
          onCloseModal();
          setFormData([]);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }

  const toggleCreateModal = () => {
    setOpenCreateModal(false);
  };

  const deleteData = async (id) => {
    const response = await ExecutorsService.delete(id);
    if (response?.statusCode === 200) {
      loadExecutors();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => setOpenCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0"></th>
              <th className="border-0">Ad</th>
              <th className="border-0">Vəzifə</th>
              <th className="border-0">Struktur</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {executors
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.order}</td>
                  <td>{item.fullname_az}</td>
                  <td>{item.position_az}</td>
                  <td>{item.structure?.name_az}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>

      {/* edit */}
      <Modal
        className="myModal"
        show={openModal}
        size="lg"
        onClose={() => onCloseModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>
                  Structure{" "}
                  {errors.structureId && (
                    <span className="text text-danger">
                      {errors.structureId.message}
                    </span>
                  )}
                </label>

                <select
                  name="structureId"
                  onChange={handleChange}
                  value={selectedCategory}
                  className={`form-control ${errors.structureId && "is-invalid"
                    }`}
                >
                  <option >Struktur seç</option>
                  {structures &&
                    structures.map((s, index) => (
                      <option value={s.id} key={index}>
                        {s.name_az}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Sıra{" "}
                  {errors.order && (
                    <span className="text text-danger">
                      {errors.order.message}
                    </span>
                  )}
                </label>
                <input
                  type="number"
                  name="order"
                  onChange={handleChange}
                  className={`form-control ${errors.order && "is-invalid"
                    }`}
                  {...register("order", {
                    required: "Sıra əlavə edin",
                  })}
                />
              </div>
            </Col>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Ad Soyad (az){" "}
                  {errors.fullname_az && (
                    <span className="text text-danger">
                      {errors.fullname_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname_az"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname_az && "is-invalid"}`}
                  {...register("fullname_az", {
                    required: "Ad soyad boş ola bilməz",
                    minLength: {
                      value: 6,
                      message: "Ad soyad minimum 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad soyad (en){" "}
                  {errors.fullname_en && (
                    <span className="text text-danger">
                      {errors.fullname_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname_en"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname_en && "is-invalid"}`}
                  {...register("fullname_en", {
                    required: " Ad soyad boş ola bilməz",
                    minLength: {
                      value: 6,
                      message: " Ad soyad minimum 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Vəzifə (az){" "}
                  {errors.position_az && (
                    <span className="text text-danger">
                      {errors.position_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="position_az"
                  onChange={handleChange}
                  className={`form-control ${errors.position_az && "is-invalid"}`}
                  {...register("position_az", {
                    required: "Vəzifə boş ola bilməz",
                    minLength: {
                      value: 4,
                      message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Vəzifə (en){" "}
                  {errors.position_en && (
                    <span className="text text-danger">
                      {errors.position_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="position_en"
                  onChange={handleChange}
                  className={`form-control ${errors.position_en && "is-invalid"}`}
                  {...register("position_en", {
                    required: " Vəzifə boş ola bilməz",
                    minLength: {
                      value: 4,
                      message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(edit)}>
            Dəyiş
          </Button>
          <Button className="btn-danger" onClick={() => onCloseModal(false)}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>

      {/* create */}
      <Modal
        className="myModal"
        show={createModalShower}
        size="lg"
        onClose={() => toggleCreateModal()}
      >
        <Modal.Header className="p-3">{page.name}</Modal.Header>
        <Modal.Body className="p-3">
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>
                  Structure{" "}
                  {errors.structureId && (
                    <span className="text text-danger">
                      {errors.structureId.message}
                    </span>
                  )}
                </label>

                <select
                  name="structureId"
                  onChange={handleChange}
                  className={`form-control ${errors.structureId && "is-invalid"
                    }`}
                >
                  <option >Struktur seç</option>
                  {structures &&
                    structures.map((s, index) => (
                      <option value={s.id} key={index}>
                        {s.name_az}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <label>
                  Sıra{" "}
                  {errors.order && (
                    <span className="text text-danger">
                      {errors.order.message}
                    </span>
                  )}
                </label>
                <input
                  type="number"
                  name="order"
                  onChange={handleChange}
                  className={`form-control ${errors.order && "is-invalid"
                    }`}
                  {...register("order", {
                    required: "Sıra əlavə edin",
                  })}
                />
              </div>
            </Col>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Ad Soyad (az){" "}
                  {errors.fullname_az && (
                    <span className="text text-danger">
                      {errors.fullname_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname_az"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname_az && "is-invalid"}`}
                  {...register("fullname_az", {
                    required: "Ad soyad boş ola bilməz",
                    minLength: {
                      value: 6,
                      message: "Ad soyad minimum 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Ad soyad (en){" "}
                  {errors.fullname_en && (
                    <span className="text text-danger">
                      {errors.fullname_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="fullname_en"
                  onChange={handleChange}
                  className={`form-control ${errors.fullname_en && "is-invalid"}`}
                  {...register("fullname_en", {
                    required: " Ad soyad boş ola bilməz",
                    minLength: {
                      value: 6,
                      message: " Ad soyad minimum 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
            <Col md="12" className="mb-2">
              <div className="form-group">
                <label>
                  Vəzifə (az){" "}
                  {errors.position_az && (
                    <span className="text text-danger">
                      {errors.position_az.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="position_az"
                  onChange={handleChange}
                  className={`form-control ${errors.position_az && "is-invalid"}`}
                  {...register("position_az", {
                    required: "Vəzifə boş ola bilməz",
                    minLength: {
                      value: 4,
                      message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <label>
                  Vəzifə (en){" "}
                  {errors.position_en && (
                    <span className="text text-danger">
                      {errors.position_en.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="position_en"
                  onChange={handleChange}
                  className={`form-control ${errors.position_en && "is-invalid"}`}
                  {...register("position_en", {
                    required: " Vəzifə boş ola bilməz",
                    minLength: {
                      value: 4,
                      message: "Vəzifə minimum 4 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-end">
          <Button className="btn-success" onClick={handleSubmit(create)}>
            Əlavə et
          </Button>
          <Button className="btn-danger" onClick={() => toggleCreateModal()}>
            Bağla
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Executors;
