import React, { useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { Button, Row } from "react-bootstrap";
import routes from "../routes";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsService from "services/settingsService";
const Settings = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const [settings, setSettings] = useState([]);
  const [formData, setFormData] = useState({
    address: "",
    email:"",
    phone1:"",
    phone2:"",
    facebook:"",
    linkedin:"",
    tiktok:"",
    twitter:"",
    youtube:"",
    location:"",
  });

  const [openModal, setOpenModal] = useState(false);
  const loadSettings = async () => {
    const data = await SettingsService.getSettings();
    for (const [key, value] of Object.entries(data?.result)) {
      setValue(key, value);
    }
    setSettings(data?.result);
  };
  useEffect(() => {
    loadSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const getData = (item) => {
    setOpenModal(true);
    setFormData(item);

    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      formData[key] = value;
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setCurrentData({});
  };

  const location = useLocation();
  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  // edit
  async function edit(request) {
    console.log(request);

    const data = [];
    for (const [key, value] of Object.entries(request)) {
      data.push({ key: key, value: value });
    }
    if (isValid) {
      try {
        const response = await SettingsService.edit(data);
        if (response?.statusCode === 200) {
          loadSettings();
          toast.success(response.message);
          onCloseModal();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <form onSubmit={handleSubmit(edit)}>
          <Row>
            {/* address */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">
                  Ünvan{" "}
                  {errors.address && (
                    <span className="text text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="address"
                  onChange={handleChange}
                  placeholder="Ünvan"
                  className={`form-control ${errors.address && "is-invalid"}`}
                  {...register("address", {
                    required: "Ünvan əlavə edin",
                    minLength: {
                      value: 6,
                      message: "Ünvan min. 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
             {/* location */}
             <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="location">
                  Location {" "}
                  {errors.location && (
                    <span className="text text-danger">
                      {errors.location.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="location"
                  onChange={handleChange}
                  placeholder="Ünvan"
                  className={`form-control ${errors.location && "is-invalid"}`}
                  {...register("location", {
                    required: "Location əlavə edin",
                    minLength: {
                      value: 6,
                      message: "Location 6 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
            {/* email */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">
                  Email{" "}
                  {errors.email && (
                    <span className="text text-danger">
                      {errors.email.message}
                    </span>
                  )}
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="email"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  {...register("email", {
                    required: "Email əlavə edin",
                    minLength: {
                      value: 10,
                      message: "Email min. 10 simvoldan ibarət olmalıdır.",
                    },
                    pattern: {
                      value: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                      message: "Düzgün email formatı daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            {/* phone */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">
                  Əlaqə nömrəsi{" "}
                  {errors.phone1 && (
                    <span className="text text-danger">
                      {errors.phone1.message}
                    </span>
                  )}
                </label>
                <input
                  type="number"
                  name="phone1"
                  onChange={handleChange}
                  placeholder="Əlaqə nömrəsi"
                  className={`form-control ${errors.phone1 && "is-invalid"}`}
                  {...register("phone1", {
                    required: "Əlaqə nömrəsi əlavə edin",
                    minLength: {
                      value: 10,
                      message:
                        "Əlaqə nömrəsi min. 10 simvoldan ibarət olmalıdır.",
                    },
                  })}
                />
              </div>
            </Col>
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">Əlaqə nömrəsi 2 </label>
                <input
                  type="number"
                  name="phone2"
                  onChange={handleChange}
                  placeholder="Əlaqə nömrəsi"
                  className={`form-control ${errors.phone2 && "is-invalid"}`}
                  {...register("phone2")}
                />
              </div>
            </Col>
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">facebook</label>
                <input
                  type="text"
                  name="facebook"
                  onChange={handleChange}
                  placeholder="email"
                  className={`form-control ${errors.facebook && "is-invalid"}`}
                  {...register("facebook", {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+\/?$/i,
                      message: "Düzgün link daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">
                  linkedin{" "}
                  {errors.linkedin && (
                    <span className="text text-danger">
                      {errors.linkedin.message}
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="linkedin"
                  onChange={handleChange}
                  placeholder="linkedin"
                  className={`form-control ${errors.linkedin && "is-invalid"}`}
                  {...register("linkedin", {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?$/i,
                      message: "Düzgün link daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            {/* tiktok */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">tiktok</label>
                <input
                  type="text"
                  name="tiktok"
                  onChange={handleChange}
                  placeholder="tiktok"
                  className={`form-control ${errors.tiktok && "is-invalid"}`}
                  {...register("tiktok", {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?tiktok\.com\/(@[a-zA-Z0-9_]+)\/?$/i,
                      message: "Düzgün link daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            {/* twitter */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">twitter</label>
                <input
                  type="text"
                  name="twitter"
                  onChange={handleChange}
                  placeholder="twitter"
                  className={`form-control ${errors.twitter && "is-invalid"}`}
                  {...register("twitter", {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/?$/i,
                      message: "Düzgün link daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            {/* Youtube */}
            <Col md="4" sm="6" className="mb-3">
              <div className="form-group">
                <label htmlFor="address">Youtube</label>
                <input
                  type="text"
                  name="youtube"
                  onChange={handleChange}
                  placeholder="youtube"
                  className={`form-control ${errors.youtube && "is-invalid"}`}
                  {...register("youtube", {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?youtube\.com\/(channel\/|user\/)?([a-zA-Z0-9_-]+)\/?$/i,
                      message: "Düzgün link daxil edin",
                    },
                  })}
                />
              </div>
            </Col>
            <Col md="12" sm="6" className="mb-3 text-right">
              <Button type="submit" className="btn-success">
                Dəyiş
              </Button>
            </Col>
          </Row>
        </form>
      </Card.Body>
    </>
  );
};

export default Settings;
