import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "flowbite-react";
import ReactQuill from "react-quill";
import { Button, Card, Row, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import routes from "routes";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import UsefulsService from "services/usefulService";
const Usefuls = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();

  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false)
  const [deletedData, setDeletedData] = useState([]);
  const [submitted, setSubmitted] = useState(true);

  const [doc, setDoc] = useState(null);
  const [newDoc, setNewDoc] = useState(null);

  const [formData, setFormData] = useState({
    header_az: "",
    header_en: "",
    link: "",
    date: "",
    id: "",
    order: "",
    document: null,
    isLegislation: false,
    isInternationalDocuments: false,
    isProse: false,
  });

  const [openModal, setOpenModal] = useState(false);
  const [createModalShower, setOpenCreateModal] = useState(false);

  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  const LoadData = async () => {
    try {
      const data = await UsefulsService.getUsefuls();
      setData(data?.result);
    } catch (error) {
      console.error("API error Usefuls", error);
    }
  };
  const LoadDeletedData = async () => {
    try {
      const data = await UsefulsService.getDeletedUseful();
      setDeletedData(data?.result);
    } catch (error) {
      console.error("API error News", error);
    }
  };


  useEffect(() => {
    LoadData();
  }, []);

  const getData = (item) => {
    reset();
    setOpenModal(true);
    setCurrentData(item)
    setDoc(item.document);
    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
    }

    setFormData(({
      ...item,
      date: dayjs(item.date).format("DD MMM YYYY")
    }));

  };

  const toggleCreateModal = () => {
    reset();
    resetForms();
    setOpenCreateModal(!createModalShower);
  };

  const resetForms = () => {
    setFormData({
      header_az: "",
      header_en: "",
      link: "",
      date: "",
      id: "",
      document: null,
      isLegislation: false,
      isInternationalDocuments: false,
      isProse: false,
    });
    setCurrentData([])
    setDoc([])
    setData([])
  };

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    const newValue = type === "file" ? files[0] : type === "checkbox" ? checked : value;
    if (type === "file" && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (name == "document") {
          setNewDoc(e.target.result);
        }
      };

      reader.readAsDataURL(files[0]);
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });

    setValue(name, newValue);
  };


  // create
  const create = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      if (!value || typeof value === "object" || typeof value === "boolean") continue;
      formData[key] = value == 'on' ? true : value == 'off' ? false : value.trim();
    }

    if (isValid) {
      if (submitted) {
        setSubmitted(false);
        try {
          const response = await UsefulsService.create(formData);
          if (response?.statusCode === 200) {
            setFormData((data) => ({
              ...data,
              id: response?.result,
            }));
            createDoc(response.result);
            LoadData();
            toast.success(response?.message);
            resetForms();
            setOpenCreateModal(false);

          }
          setSubmitted(true)
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    }
  };


  const createDoc = async (id) => {
    let data = new FormData();
    data.append("document", formData.document);
    if (formData.document) {
      const response = await UsefulsService.addDoc(id, data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
        resetForms();
        setOpenCreateModal(false);
      } else {
        toast.success(response?.message);
      }
      setSubmitted(true);
    }
  };

  const editDoc = async (id) => {
    let data = new FormData();
    data.append("id", id);
    data.append("document", formData.document);
    if (formData.document) {
      const response = await UsefulsService.editDoc(data);
      if (response?.statusCode === 200) {
        LoadData();
        setNewDoc(null);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } else {
      toast.error("Şəkil əlavə edin");
    }
  };

  // edit
  const edit = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      if (!value || typeof value === "object" || typeof value === "boolean") continue;
      formData[key] = value == 'on' ? true : value == 'off' ? false : value.trim();
    }

    const response = await UsefulsService.edit(formData);
    if (response?.statusCode === 200) {
      LoadData();
      toast.success(response.message);
      setOpenModal(false);
      setFormData([]);
    } else {
      toast.error(response.message);
    }
  };


  const deleteData = async (id) => {
    const response = await UsefulsService.delete(id);
    if (response?.statusCode === 200) {
      LoadData();
      resetForms();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };
  const showDeletedDatas = async () => {
    setShowDeleted(!showDeleted)
    LoadDeletedData();
  }
  const unDelete = async (data) => {
    const response = await UsefulsService.undelete(data.id);
    if (response?.statusCode === 200) {
      setShowDeleted(false)
      LoadData();
      resetForms();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => toggleCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <button
          onClick={() => showDeletedDatas(true)}
          className={`btn ml-3 ${showDeleted ? "btn-danger" : "btn-secondary "}`}
        > Silinmiş
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Başlıq</th>
              <th className="border-0">Link</th>
              <th className="border-0">Sənəd</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          {!showDeleted && (
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  <td>{item.order}</td>
                  <td>{item.header_az}</td>
                  <td>{item.link}</td>
                  <td>
                    {item.document && item.document.id && (

                      <a target="_blank" className="text text-info" download href={item.document && item.document.name}>
                        <i className="fa fa-file"></i>
                        Sənəd
                      </a>
                    )}
                  </td>
                  <td> {dayjs(item.date).format('DD.MM.YYYY')}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => getData(item)}>
                        Düzəliş
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteData(item.id)}>
                        Sil
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {showDeleted && (
            <tbody>
              {deletedData?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>{item.header}</td>
                  <td>{item.link}</td>
                  <td> {dayjs(item.date).format('DD.MM.YYYY')}</td>
                  <td className=" pr-3">
                    <DropdownButton id={`conf-button_${index}`} title="">
                      <Dropdown.Item onClick={() => unDelete(item)}>
                        Geri qaytar
                      </Dropdown.Item>
                    </DropdownButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

        </Table>
        {/* edit */}
        <Modal
          className="myModal"
          show={openModal}
          size="4xl"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    {" "}
                    {errors.document && (
                      <span className="text text-danger p-3">
                        {errors.document.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newDoc && (
                    <a
                      target="_blank"
                      className="text text-info"
                      href={doc?.name}
                    >
                      <i className="fa fa-file"></i>
                      Sənəd
                    </a>
                  )}
                  {newDoc && (
                    <a className="text text-info" href={newDoc}>
                      <i className="fa fa-file"></i>
                      Yeni Sənəd
                    </a>
                  )}
                  <input
                    type="file"
                    name="document"
                    onChange={(e) => {
                      handleChange(e);
                      register("document");
                    }}
                    className={`form-control`}
                  />
                  {newDoc && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editDoc(doc.id)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Sıra{" "}
                    {errors.order && (
                      <span className="text text-danger">
                        {errors.order.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    name="order"
                    onChange={handleChange}
                    className={`form-control ${errors.order && "is-invalid"
                      }`}
                    {...register("order", {
                      required: "Sıra əlavə edin",
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  {/* Qanunvericilik */}
                  <div>
                    <input
                      type="checkbox"
                      id="isLegislation"
                      name="isLegislation"
                      checked={formData.isLegislation}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isLegislation && "is-invalid"
                        }`}
                    />
                    <label htmlFor='isLegislation' className="cp">
                      Qanunvericilik{" "}
                    </label>
                  </div>

                  {/* Beynəlxalq sənədlər */}
                  <div>

                    <input
                      type="checkbox"
                      id="isInternationalDocuments"
                      name="isInternationalDocuments"
                      checked={formData.isInternationalDocuments}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isInternationalDocuments && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isInternationalDocuments" className="cp">
                      Beynəlxalq sənədlər{" "}
                    </label>
                  </div>

                  {/* Nəşrlər */}
                  <div>
                    <input
                      type="checkbox"
                      name="isProse"
                      id="isProse"
                      checked={formData.isProse}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isProse && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isProse" className="cp">
                      Nəşrlər{" "}
                    </label>
                  </div>

                </div>
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.header_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="header_az"
                    onChange={handleChange}
                    className={`form-control ${errors.header_az && "is-invalid"
                      }`}
                    {...register("header_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.header_en && (
                      <span className="text text-danger">
                        {errors.header_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="header_en"
                    onChange={handleChange}
                    className={`form-control ${errors.header_en && "is-invalid"
                      }`}
                    {...register("header_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-12 mb-5">
                <div className="form-group">
                  <label>Link </label>
                  <input
                    type="text"
                    name="link"
                    onChange={handleChange}
                    className={`form-control ${errors.link && "is-invalid"
                      }`}
                    {...register("link", {
                      required: "Link əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Link minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>Tarix {formData.date}</label>
                  <input
                    type="date"
                    name="date"
                    onChange={handleChange}
                    className={`form-control ${errors.date && "is-invalid"
                      }`}

                  />
                </div>
              </div>

            </Row>
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(edit)}>
              Dəyiş
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModal(false)}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>

        {/* create */}
        <Modal
          className="myModal"
          show={createModalShower}
          size="4xl"
          onClose={() => toggleCreateModal()}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              {/* doc */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Sənəd{" "}
                    {errors.document && (
                      <span className="text text-danger p-3">
                        {errors.document.message}
                      </span>
                    )}
                  </label>

                  <input
                    type="file"
                    name="document"
                    onChange={(e) => {
                      handleChange(e);
                      register("document");
                    }}
                    className={`form-control ${errors.document && "is-invalid"
                      }`}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Sıra{" "}
                    {errors.order && (
                      <span className="text text-danger">
                        {errors.order.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    name="order"
                    onChange={handleChange}
                    className={`form-control ${errors.order && "is-invalid"
                      }`}
                    {...register("order", {
                      required: "Sıra əlavə edin",
                    })}
                  />
                </div>
              </div>


              {/* header */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  {/* Qanunvericilik */}
                  <div>
                    <input
                      type="checkbox"
                      id="isLegislation"
                      name="isLegislation"
                      checked={formData.isLegislation}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isLegislation && "is-invalid"
                        }`}
                    />
                    <label htmlFor='isLegislation' className="cp">
                      Qanunvericilik{" "}
                    </label>
                  </div>

                  {/* Beynəlxalq sənədlər */}
                  <div>

                    <input
                      type="checkbox"
                      id="isInternationalDocuments"
                      name="isInternationalDocuments"
                      checked={formData.isInternationalDocuments}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isInternationalDocuments && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isInternationalDocuments" className="cp">
                      Beynəlxalq sənədlər{" "}
                    </label>
                  </div>

                  {/* Nəşrlər */}
                  <div>
                    <input
                      type="checkbox"
                      name="isProse"
                      id="isProse"
                      checked={formData.isProse}
                      onChange={handleChange}
                      className={`form-control mr-3 ${errors.isProse && "is-invalid"
                        }`}
                    />
                    <label htmlFor="isProse" className="cp">
                      Nəşrlər{" "}
                    </label>
                  </div>


                </div>
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.header_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="header_az"
                    onChange={handleChange}
                    className={`form-control ${errors.header_az && "is-invalid"
                      }`}
                    {...register("header_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.header_en && (
                      <span className="text text-danger">
                        {errors.header_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="header_en"
                    onChange={handleChange}
                    className={`form-control ${errors.header_en && "is-invalid"
                      }`}
                    {...register("header_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>

              {/* link */}
              <div className="col-md-12 mb-2">
                <div className="form-group">
                  <label>
                    Link {" "}
                    {errors.link && (
                      <span className="text text-danger">
                        {errors.link.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="link"
                    onChange={handleChange}
                    className={`form-control ${errors.link && "is-invalid"
                      }`}
                    {...register("link", {
                      required: "link əlavə edin",
                      minLength: {
                        value: 6,
                        message: "link minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              {/* Date */}
              <div className="col-md-12 mb-2">
                <div className="form-group">
                  <label>
                    Tarix (az){" "}
                    {errors.date && (
                      <span className="text text-danger">
                        {errors.date.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="date"
                    name="date"
                    onChange={handleChange}
                    className={`form-control ${errors.date && "is-invalid"
                      }`}
                    {...register("date", {
                      required: "Tarix əlavə edin",
                    })}
                  />
                </div>
              </div>

            </Row>
          </Modal.Body>
          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={handleSubmit(create)}>
              Əlavə et
            </Button>
            <Button className="btn-danger" onClick={() => toggleCreateModal()}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </>
  );
};

export default Usefuls;
