// src/services/newsService.js

import { toast } from "react-toastify";
import { get, del, post, put } from "./api-service";

const NewsService = {
  getNews: async () => {
    try {
      const response = await get("admin/news/getall").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getDeletedNews: async () => {
    try {
      const response = await get("admin/news/getall-delete").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    try {
      const response = await post("admin/news", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (request) => {
    try {
      const response = await put("admin/news", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  editPhoto: async (request) => {
    const response = await put("admin/news/photo/", request);
    return response;
  },
  delete: async (request) => {
    try {
      const response = await del("admin/news/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  undelete: async (request) => {
    try {
      const response = await put("admin/news/revert/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  setPhoto: async (id, request) => {
    try {
      const response = await post("admin/news/add-photo/" + id, request);
      if (response.statusCode !== 200) {
        throw new Error("API error");
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  addDoc: async (id, request) => {
    try {
      const response = await post("admin/news/add-document/" + id, request);

      if (response.statusCode !== 200) {
        throw new Error("API error");
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
  editDoc: async (request) => {
    const response = await put("admin/news/document", request);
    return response;
  },
  delPhoto: async (request) => {
    const response = await del("admin/news/remove-photo/" + request, "");
    return response;
  },
};

export default NewsService;
