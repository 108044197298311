import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "flowbite-react";
import ReactQuill from "react-quill";
import { Button, Card, Row, Table } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import routes from "routes";
import qmodules from "qmodules";
import qformats from "qformats";
import parse from "html-react-parser";
import CommonService from "services/commonService";
import { toast } from "react-toastify";

const Common = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();

  const [data, setData] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [newPhoto, setNewPhoto] = useState(null);
  const [doc, setDoc] = useState(null);
  const [newDoc, setNewDoc] = useState(null);
  const [formData, setFormData] = useState({
    header_az: "",
    header_en: "",
    text_en: "",
    document: "",
    text_az: "",
    id: "",
  });

  const [openModal, setOpenModal] = useState(false);
  const [createModalShower, setOpenCreateModal] = useState(false);

  const page =
    routes.find((x) => x.path === location.pathname.replace("/admin", "")) ??
    "";

  const LoadData = async () => {
    try {
      const data = await CommonService.getCommon();
      setData(data?.result);
    } catch (error) {
      console.error("API error Common", error);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  const getData = (item) => {
    reset();
    setOpenModal(true);
    setPhoto(item.photo);
    setNewPhoto(null);
    setDoc(item.document);
    for (const [key, value] of Object.entries(item)) {
      setValue(key, value);
      setFormData({
        ...formData,
        [key]: value,
      });
    }

    setFormData({
      ...formData,
      text_az: item.text_az,
      text_en: item.text_en,
      id: item.id,
    });
  };

  const toggleCreateModal = () => {
    reset();
    resetForms();
    setOpenCreateModal(!createModalShower);
  };

  const resetForms = () => {
    setPhoto(null);
    setNewPhoto(null);
    setFormData({
      text_az: "",
      text_en: "",
      header_az: "",
      header_en: "",
      document: "",
      image: null,
      id: "",
    });
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setNewPhoto(null);
    if (type === "file" && files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (name == "document") {
          setNewDoc(e.target.result);
        }
        else {
          setNewPhoto(e.target.result);
        }
      };
      reader.readAsDataURL(files[0]);
    }
    setFormData({
      ...formData,
      [name]: newValue,
    });

    setValue(name, newValue);
  };

  const changeTextEditor = (value, name) => {
    setValue(name, value);
  };
  // edit
  const edit = async (request) => {
    for (const [key, value] of Object.entries(request)) {
      if (key !== "image" && key !== "document" && key !== "photos" && key !== "photo") {
        formData[key] = value?.trim();
      }
    }

    const response = await CommonService.edit(formData);
    if (response?.statusCode === 200) {
      LoadData();
      toast.success(response.message);
      setOpenModal(false);
      setFormData([]);
    } else {
      toast.error(response.message);
    }
  };
  const editDoc = async (id) => {
    let data = new FormData();
    data.append("id", id);
    data.append("document", formData.document);
    if (formData.document) {
      const response = await CommonService.editDoc(data);
      if (response?.statusCode === 200) {
        LoadData();
        setNewDoc(null);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } else {
      toast.error("Şəkil əlavə edin");
    }
  };

  const submitForm = () => {
    handleSubmit(edit)();
  };
  const editPhoto = async (id) => {
    let data = new FormData();
    data.append("id", id);
    data.append("photo", formData.image);
    if (formData.image) {
      const response = await CommonService.editPhoto(data);
      if (response?.statusCode === 200) {
        LoadData();
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    } else {
      toast.error("Şəkil əlavə edin");
    }
  };

  return (
    <>
      <Card.Body className="table-full-width table-responsive px-0">
        <button
          onClick={() => toggleCreateModal(true)}
          className="btn btn-success"
        >
          <i className="nc-icon  nc-simple-add"></i> Yeni
        </button>
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">Şəkil</th>
              <th className="border-0">Başlıq</th>
              <th className="border-0">Sənəd</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <img width="200px" src={item?.photo?.photoName} />
                </td>
                  <td>{item.header_az}</td>
                <td>
                  {item?.document?.name && (

                    <a target="_blank" className="text text-info" download href={item.document.name}>
                      <i className="fa fa-file"></i>
                      Sənəd
                    </a>
                  )}
                </td>
                <td className=" pr-3">
                  <DropdownButton id={`conf-button_${index}`} title="">
                    <Dropdown.Item onClick={() => getData(item)}>
                      Düzəliş
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* edit */}
        <Modal
          className="myModal"
          show={openModal}
          size="7xl"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header className="p-3">{page.name}</Modal.Header>
          <Modal.Body className="p-3">
            <Row>
              {/* photo */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Şəkil{" "}
                    {errors.image && (
                      <span className="text text-danger p-3">
                        {errors.image.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newPhoto && photo && (
                    <img width="250" src={photo.photoName} alt="Preview" />
                  )}
                  {newPhoto && (
                    <img width="250" src={newPhoto} alt="Preview" />
                  )}
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      handleChange(e);
                      register("image");
                    }}
                    className={`form-control`}
                  />
                  {/* edit photo button */}
                  {newPhoto && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editPhoto(formData.id)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div>
              </div>
              {/* doc */}
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    {" "}
                    {errors.document && (
                      <span className="text text-danger p-3">
                        {errors.document.message}
                      </span>
                    )}{" "}
                  </label>
                  {!newDoc && (
                    <a
                      target="_blank"
                      className="text text-info"
                      href={doc?.name}
                    >
                      <i className="fa fa-file"></i>
                      Sənəd
                    </a>
                  )}
                  {newDoc && (
                    <a className="text text-info" href={newDoc}>
                      <i className="fa fa-file"></i>
                      Yeni Sənəd
                    </a>
                  )}
                  <input
                    type="file"
                    name="document"
                    onChange={(e) => {
                      handleChange(e);
                      register("document");
                    }}
                    className={`form-control`}
                  />
                  {newDoc && (
                    <Button
                      className="btn-warning mt-3"
                      onClick={() => editDoc(doc.id)}
                    >
                      Dəyiş
                    </Button>
                  )}
                </div>
              </div>
              <div className="col-md-6  mb-2">
                <div className="form-group">
                  <label>
                    Başlıq (az){" "}
                    {errors.header_az && (
                      <span className="text text-danger">
                        {errors.header_az.message}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    name="header_az"
                    onChange={handleChange}
                    className={`form-control ${errors.header_az && "is-invalid"
                      }`}
                    {...register("header_az", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
             
              </div>
              <div className="col-md-6  mb-2">
            
                <div className="form-group">
                  <label>
                    Başılq (en){" "}
                    {errors.header_en && (
                      <span className="text text-danger">
                        {errors.header_en.message}
                      </span>
                    )}{" "}
                  </label>
                  <input
                    type="text"
                    name="header_en"
                    onChange={handleChange}
                    className={`form-control ${errors.header_en && "is-invalid"
                      }`}
                    {...register("header_en", {
                      required: "Başlıq əlavə edin",
                      minLength: {
                        value: 6,
                        message: "Başlıq minimum 6 simvoldan ibarət olmalıdır.",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir az </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.text_az}
                    onChange={(value, name) =>
                      changeTextEditor(value, "text_az")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
              <div className="col-md-6 mb-5">
                <div className="form-group">
                  <label>Təsvir en </label>
                  <ReactQuill
                    theme="snow"
                    modules={qmodules}
                    formats={qformats}
                    value={formData.text_en}
                    onChange={(value, name) =>
                      changeTextEditor(value, "text_en")
                    }
                    placeholder="write your content ...."
                    style={{ height: "150px" }}
                  ></ReactQuill>
                </div>
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer className="text-end">
            <Button className="btn-success" onClick={submitForm}>
              Dəyiş
            </Button>
            <Button className="btn-danger" onClick={() => setOpenModal(false)}>
              Bağla
            </Button>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </>
  );
};

export default Common;
