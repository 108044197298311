// src/services/usefulService.js

import { toast } from "react-toastify";
import { get, del, post, put } from "./api-service";

const UsefulsService = {
  getUsefuls: async () => {
    try {
      const response = await get("admin/useful/getall").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getDeletedUseful: async () => {
    try {
      const response = await get("admin/useful/getall-delete").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (request) => {
    try {
      const response = await post("admin/useful", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (request) => {
    try {
      const response = await put("admin/useful/text", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  editPhoto: async (request) => {
    const response = await put("admin/useful/photo/", request);
    return response;
  },
  delete: async (request) => {
    try {
      const response = await del("admin/useful/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response; 
      return data;
    } catch (error) {
      throw error;
    }
  },
  undelete: async (request) => {
    try {
      const response = await put("admin/useful/revert/" + request, "");
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  addDoc: async (id, request) => {
    try {
      const response = await post("admin/useful/add-document/" + id, request);

      if (response.statusCode !== 200) {
        throw new Error("API error");
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
  editDoc: async (request) => {
    const response = await put("admin/useful/document", request);
    return response;
  },

};

export default UsefulsService;
