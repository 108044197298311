// src/services/commonService.js

import { toast } from "react-toastify";
import { get, del, post, put } from "./api-service";

const CommonService = {
  getCommon: async () => {
    try {
      const response = await get("admin/commontext/getall").catch((error) =>
        console.error(error)
      );

      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  edit: async (request) => {
    try {
      const response = await put("admin/commontext", request);
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }

      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  editPhoto: async (request) => {
    const response = await put("admin/commontext/photo/", request);
    return response;
  },
  
  addDoc: async (id, request) => {
    try {
      const response = await post("admin/commontext/add-document/" + id, request);

      if (response.statusCode !== 200) {
        throw new Error("API error");
      }

      return response;
    } catch (error) {
      throw error;
    }
  },
  editDoc: async (request) => {
    const response = await put("admin/commontext/document", request);
    return response;
  },

};

export default CommonService;
