
import { get,post } from "./api-service";

const LoginService = {
  login: async (request) => {
    try {
      const response = await post("admin/login",request).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  logout: async () => {
    try {
      const response = await get("admin/logout").catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default LoginService;
