import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import { UserProvider } from "services/user-context";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <UserProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/admin/*" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </UserProvider>
);
